/*
* mixin: Respond-To
* Область видимости ДО определенного брейкпоинта
*/
@mixin respond-to($media) {
  @if $media == small {
    @media only screen and (max-width: $breakpoint-xsmall-max) {
      @content;
    }
  } @else if $media == medium {
    @media only screen and (max-width: $breakpoint-small-max) {
      @content;
    }
  } @else if $media == large {
    @media only screen and (max-width: $breakpoint-medium-max) {
      @content;
    }
  } @else if $media == xlarge {
    @media only screen and (max-width: $breakpoint-large-max) {
      @content;
    }
  }
}

/*
* mixin: Respond-From
* Область видимости ОТ определенного брейкпоинта
*/
@mixin respond-from($media) {
  @if $media == small {
    @media only screen and (min-width: $breakpoint-small) {
      @content;
    }
  } @else if $media == medium {
    @media only screen and (min-width: $breakpoint-medium) {
      @content;
    }
  } @else if $media == large {
    @media only screen and (min-width: $breakpoint-large) {
      @content;
    }
  } @else if $media == xlarge {
    @media only screen and (min-width: $breakpoint-xlarge) {
      @content;
    }
  }
}

/*
* mixin: Respond-In
* Область видимости В определенной области между брейкпоинтами
* TODO: Доделать
*/
@mixin respond-in($media) {
  @if $media == small {
    @media only screen and (max-width: 640px) {
      @content;
    }
  } @else if $media == medium {
    @media only screen and (max-width: 960px) {
      @content;
    }
  } @else if $media == large {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  } @else if $media == xlarge {
    @media only screen and (max-width: 1600px) {
      @content;
    }
  }
}
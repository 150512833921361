/*
 * Управление цветом кнопки
 */
@mixin button-bg($bg) {
  background: $bg;
  border-color: $bg;
  &:hover {
    background: darken($bg, 8%);
    transition: all 0.3s ease;
  }
  &:active {
    background: darken($bg, 25%);
  }
}
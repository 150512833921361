@import 'mixins/grid';
@import 'mixins/colors';
@import 'mixins/inputs';
@import 'parts/vars';
@import 'parts/fonts';
@import 'parts/uikit';
@import 'parts/uikit-extends';
@import "parts/typography";
@import "parts/buttons";
@import "parts/settings";
@import "parts/video";
@import "parts/blocks";
@import "parts/mobile";
@import "parts/inputs";

html {
  // Убираем серое выделение при тапе на ссылках
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// Десктопная шапка
$desktop-header-phone-color: #4A4A4A;
.desktop-header {
  &__container {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &__logo {
    height: 60px;
    @extend .uk-flex;
    @extend .uk-flex-middle;
    // font-size: 50px;
    // font-weight: 600;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__desc {
    color: #000;
    font-size: 14px;
    font-weight: 300;
    height: 100%;
    @extend .uk-flex;
    @extend .uk-flex-middle;
  }

  &__phone {
    color: $desktop-header-phone-color;
    font-size: 26px;
    font-weight: 300;
    text-align: right;

    &:hover {
      color: $desktop-header-phone-color;
      text-decoration: none;
    }

    span {
      font-weight: 600;
    }
  }

  &__address {
    color: fade_out(#000, .5);
    font-size: 14px;
    font-weight: 300;
    text-align: right;
  }

  &__worktime {
    color: #9B9B9B;
    font-size: 14px;
    font-weight: 300;
  }

  &__email {
    color: #000;
    font-weight: 300;
    font-size: 16px;
  }

  &__icon {
    display: block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &--vk {

    }
  }

  &__button {
    padding: 0 15px;
    font-size: 14px;
    line-height: 35px;
    @extend .uk-button;
    @extend .uk-button-medium;
    @extend .uk-button-primary;
    @extend .uk-border-rounded;
  }
}

// Поиск по сайту в блоке меню
.searchform {
  &__container {

  }

  &__form {
    width: 200px !important;
    @extend .uk-flex;
    @extend .uk-search;
    @extend .uk-search-default;
  }

  &__input {
    color: #fff;
    font-size: 14px;
    height: 30px !important;
    background-color: fade-out(#fff, .8) !important;
    border: 1px solid transparent !important;
    @extend .uk-border-rounded;
    @extend .uk-search-input;
  }

  &__button {
    color: #fff;
    @extend .uk-button;
    @extend .uk-button-primary;
    @extend .uk-border-rounded;
  }
}
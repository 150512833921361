/*
 * Стили для мобильных фишек
 */

// Отображение блока на разных устройствах
.visible {
  &__mobile {
    @include respond-from(small) {
      display: none !important;
    }
  }

  &__desktop {
    @include respond-to(small) {
      display: none !important;
    }
  }
}

// Горизонтальный скролл для UIKIT3
.mobileHorisontalScroll {
  @include respond-to(small) {
    display: grid;
    grid-gap: calc(#{$global-medium-gutter} / 2);
    grid-template-columns: 0;
    // grid-template-rows: minmax(150px, 1fr);
    grid-auto-flow: column;
    // grid-auto-columns: calc(50% - #{$global-gutter} * 2);

    overflow-x: scroll;
    scroll-snap-type: x proximity;
    -webkit-overflow-scrolling: touch; // добавляет плавную прокрутку
    &::-webkit-scrollbar {
      display: none;
    }

    grid-column: 1 / -1;

    &:before {
      content: '';
      width: 0;
    }

    &:after {
      content: '';
      width: 5px;
    }

    &__wrapper {
      overflow: hidden;

      // Убираем отступы контейнера
      margin-left: -$container-padding-horizontal;
      margin-right: -$container-padding-horizontal;
    }

    // Скрываем горизонтальный скроллбар
    padding-bottom: 15px;
    margin-bottom: -15px;
    margin-right: 0;
    margin-left: 0;

    & > div {
      padding-left: 0 !important;
    }

    //margin-right: -$container-padding-horizontal; // убираем отступ контейнера справа
  }
}

// Четырехпозиционная прокрутка
.mobile-xy-overflow-scroll {
  margin-left: -15px;
  margin-right: -15px;

  &__inner {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.mobile {
  @include respond-to(small) {
    &-margin-remove-top {
      margin-top: 0 !important;
    }

    &-margin-remove-bottom {
      margin-bottom: 0 !important;
    }
  }
}

// Блок на всю ширину на мобильном
.mobile-full-width-block {
  @include respond-to(small) {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
}

@mixin mobile-full-width-block() {
  @include respond-to(small) {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
}

.mobile-reverse {
  @include respond-to(small) {
    display: flex;
    flex-flow: column-reverse;
  }
}
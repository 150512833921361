@import "../mixins/buttons";

/*
 * Создаем кнопки с базовыми цветами
 */
.button {
  padding: .5rem 1rem;
  line-height: normal;

  // Размеры
  &--small{
    font-size: .8rem;
    padding: .3rem .8rem;
  }
  &--large{
    font-size: 1rem;
    padding: 1rem 2rem;
  }

  // Скругления
  &--border-rounded{
    border-radius: .3rem;
  }
  &--border-circle{
    border-radius: 5rem;
  }

  // Рамочная кнопка
  &--rounded{
    background: transparent;
    color: #000;
    border: 1px solid #000;
  }

  @each $status, $color in $colors {
    &--#{$status} {
      @include button-bg($color);
    }
  }
}
/*
 * Задает стили плейсхолдерам
 */
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

/*
 * Управление цветом кнопки
 */
@mixin button-bg($bg) {
  background: $bg;
  border-color: $bg;
  &:hover {
    background: darken($bg, 8%);
    transition: all 0.3s ease;
  }
  &:active {
    background: darken($bg, 25%);
  }
}

/*
 * input-text
 */
@mixin sassy-text {
  box-sizing: border-box;
  min-height: rem(40px);
  width: 100%;
  padding: rem(10px);
  margin-bottom: rem(10px);
  font-family: inherit;
  font-size: rem(14px);
  vertical-align: middle;
  border: rem(1px) solid lighten($color-main, 10%);
  border-radius: rem(3px);
  outline: none;
  background-color: white;
  &:focus {
    border-color: $color-main;
  }
  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }
}

/*
Input Search
*/
@mixin sassy-search {
  @include sassy-text;
  padding: 0 rem(4px);
}

/*
Textarea
*/
@mixin sassy-textarea {
  @include sassy-text;
  display: block;
  height: 100px;
  overflow: auto;
}

/*
Селекты
*/
@mixin sassy-select {
  position: relative;
  color: inherit;

  &::after {
    content: '\25BE';
    position: absolute;
    top: rem(10px);
    right: rem(15px);
    color: $color-main;
    pointer-events: none;
  }

  /* Target IE9 and IE10 */
  select::-ms-expand {
    display: none;
  }

  select {
    height: rem(39px);
    width: 100%;
    padding-left: rem(10px);
    margin-bottom: rem(10px);
    font-family: inherit;
    font-size: rem(14px);

    border: rem(1px) solid lighten($color-main, 10%);
    border-radius: rem(3px);
    outline: none;

    background-color: white;

    &:focus {
      border-color: $color-main;
    }

    &:disabled {
      background-color: $color-main;
      cursor: not-allowed;
    }
  }
}

@mixin sassy-select-multiple {
  width: 100%;
  padding: 0 rem(10px);
  margin-bottom: rem(10px);
  font-family: inherit;
  font-size: rem(14px);

  border: rem(1px) solid lighten($color-main, 10%);
  border-radius: rem(3px);
  outline: none;

  background-color: white;

  &:focus {
    border-color: $color-main;
  }

  &:disabled {
    background-color: $color-main;
    cursor: not-allowed;
  }
}

/*
Радиобаттоны
*/
@mixin sassy-radio {
  position: absolute;
  left: -1000rem;

  + label {
    position: relative;
    cursor: pointer;
    margin-right: rem(15px);
    padding-left: rem(25px);
    font-size: rem(14px);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: rem(18px);
      width: rem(18px);
      border-radius: rem(9px);
      background-color: $color-main;
      font-family: sans-serif;
    }

    &::after {
      content: '';
      transition: background-color 0.3s;
    }
  }

  &:checked + label::after {
    content: '';
    position: absolute;
    left: rem(5px);
    top: rem(5px);
    height: rem(8px);
    width: rem(8px);
    border-radius: rem(15px);
    background-color: $color-main;
    text-align: center;
  }

  &:focus + label::before {
    box-shadow: 0 rem(1px) 0 darken($color-main, 30%);
  }

  &:disabled {
    + label {
      cursor: not-allowed;

      &::before {
        background-color: $color-main;
      }
    }

    &:checked + label::after {
      background-color: darken($color-main, 30%);
    }
  }
}

/*
Чекбоксы
*/
@mixin sassy-checkbox {
  position: absolute;
  left: -1000rem;

  + label {
    position: relative;
    cursor: pointer;
    margin-right: rem(15px);
    padding-left: rem(25px);
    font-size: rem(14px);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: rem(16px);
      width: rem(16px);
      border-radius: rem(3px);
      background-color: $color-main;
      font-family: sans-serif;
    }

    &::after {
      content: '';
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  /* Not depending on the user's font here improves consistency. */
  &:checked + label::after {
    content: '';
    position: absolute;
    display: block;
    opacity: 1;
    left: rem(5px);
    top: 0;
    width: rem(3px);
    height: rem(11px);
    border: solid $color-main;
    border-width: 0 rem(3px) rem(3px) 0;
    transform: rotate(45deg);
    line-height: rem(16px);
  }

  &:focus + label::before {
    box-shadow: 0 rem(1px) 0 darken($color-main, 30%);
  }

  &:disabled {
    + label {
      cursor: not-allowed;

      &::before {
        background-color: $color-main;
      }
    }

    &:checked + label::after {
      border-color: darken($color-main, 30%);
    }
  }
}
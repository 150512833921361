//Брейкпоинты
$breakpoint-small: 480px;

$global-emphasis-color: #000;

// Отступы
$global-gutter: 20px;
$global-small-gutter: 10px;
$global-medium-gutter: 30px;
$global-large-gutter: 40px;

// Паддинги
$padding-padding: $global-gutter;
$padding-padding-l: $global-gutter;
$padding-padding-xl: $global-gutter * 1.5;
$padding-small-padding: $global-small-gutter;
$padding-large-padding: $global-gutter;
$padding-large-padding-l: $global-large-gutter;

// Маргины
$margin-margin: 20px;
$margin-small-margin: 10px;
$margin-medium-margin: 30px;
$margin-large-margin: 40px;
$margin-xlarge-margin: 70px;

//Кнопки
$button-line-height: 48px;
$button-small-line-height: 28px;
$button-medium-line-height: 38px;
$button-large-line-height: 58px;
$button-font-size: 1rem;
$button-small-font-size: 1rem;
$button-medium-font-size: 1rem;
$button-large-font-size: 1rem;

// Button Primary
$button-primary-color: #000;
$button-primary-hover-color: #000;

// Формы
$form-height: 50px;
$form-label-font-size: 14px;
$form-placeholder-color: #000;
$form-padding-horizontal: 10px;
$form-border-width: 1px;

// Закругление
$border-rounded-border-radius: 5px;

// Перегрузка цветов
$global-link-color: $color-primary;
$global-link-hover-color: $color-primary;
$global-color: #000;
$global-primary-background: $color-primary;
$global-secondary-background: $color-secondary;
$global-danger-background: $color-danger;

$inverse-base-color: #fff;

/*
 * Настройка главного меню
 */
// главное меню
$navbar-nav-item-height: 50px;
$navbar-background: $color-primary;
$navbar-nav-item-color: #fff;
$navbar-nav-item-hover-color: #fff;
$navbar-nav-item-active-color: #fff;

// Панировочные сухари
$breadcrumb-divider: '›';
$breadcrumb-divider-margin-horizontal: 10px;
$breadcrumb-item-font-size: 14px;

// Дотнавы для слайдера
$dotnav-item-active-background: $color-primary;
$dotnav-item-active-border: $color-primary;
$dotnav-item-background: #979797;
$dotnav-item-border: #979797;
$dotnav-margin-horizontal: 7px;

//Шрифты
$global-font-family: $font-main;
$base-heading-font-family: $font-main;

// Cслыки
$inverse-base-link-color: #fff;

// Кнопки
$button-primary-background: $global-primary-background;
$button-primary-hover-background: darken($global-primary-background, 5%);
$inverse-button-default-background: $global-primary-background;
$inverse-button-default-color: #fff;
$inverse-button-default-hover-background: darken($global-primary-background, 5%);
$inverse-button-default-hover-color: #fff;

$inverse-button-primary-background: $global-primary-background;
$inverse-button-primary-color: #fff;
$inverse-button-primary-hover-background: darken($global-primary-background, 5%);
$inverse-button-primary-hover-color: #fff;
$inverse-button-primary-active-background: darken($global-primary-background, 10%);
$inverse-button-primary-active-color: #fff;

$button-padding-horizontal: 30px;

// Табы
$tab-item-text-transform: none;
$tab-margin-horizontal: 20px;
$tab-item-padding-horizontal: 10px;
$tab-item-padding-vertical: 5px;
$tab-item-hover-color: $global-color;
$tab-item-hover-text-decoration: none;

//Канвас
$offcanvas-bar-width: 270px;
$offcanvas-bar-background: #fff;
$offcanvas-bar-color-mode: unset;
$offcanvas-bar-width-m: 350px;
$offcanvas-bar-padding-vertical-m: $global-medium-gutter;
$offcanvas-bar-padding-horizontal-m: $global-medium-gutter;
$offcanvas-close-position: 20px;
$offcanvas-close-padding: 5px;
$offcanvas-overlay-background: rgba(0, 0, 0, 0.5);

// Табы
$subnav-pill-item-padding-vertical: 5px;
$subnav-pill-item-padding-horizontal: 10px;
$subnav-pill-item-background: transparent;
$subnav-pill-item-hover-color: $global-color;
$subnav-pill-item-onclick-color: $subnav-pill-item-hover-color;
$subnav-pill-item-active-background: $global-primary-background;

// Lightbox
$lightbox-toolbar-color: rgba(255, 255, 255, 0.7);
$lightbox-toolbar-icon-padding: 5px; // должен быть в пикселах

@import '../../../node_modules/uikit/src/scss/variables-theme'; // UIKIT через NodeJS

@import '../../../node_modules/uikit/src/scss/mixins-theme'; // UIKIT через NodeJS

// Перегрузка миксинов
@mixin hook-card() {
  color: #000;
}

@import '../../../node_modules/uikit/src/scss/uikit'; // UIKIT через NodeJS
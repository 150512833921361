form {
  input[type=number],
  input[type=text],
  input[type=email] {
    @extend .uk-input;
  }

  textarea {
    @extend .uk-textarea;
  }
}
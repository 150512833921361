// Убираем uppercase у пунктов меню
.uk-navbar-nav {
  & > li {
    & > a {
      text-transform: none !important;
    }
  }
}

// Убираем границы у линованой таблицы
.uk-table-striped tbody tr:nth-of-type(2n+1),
.uk-table-striped > tr:nth-of-type(2n+1) {
  border: none !important;
}

.uk-tab {
  // Убираем uppercase у табов
  & > * > a {
    text-transform: none !important;
  }
}

.uk {
  &-container {
    &-small {
      &\@s {
        @include respond-from(small) {
          max-width: $container-small-max-width;
        }
      }
    }
  }

  &-margin {
    &-large {
      &\@s {
        @include respond-from(small) {
          * + & {
            margin-top: $margin-large-margin;
          }
          margin-bottom: $margin-large-margin;
        }
      }
    }

    &-xlarge {
      margin-top: $margin-xlarge-margin;
      margin-bottom: $margin-xlarge-margin;

      &\@s {
        @include respond-from(small) {
          * + & {
            margin-top: $margin-xlarge-margin;
          }
          margin-bottom: $margin-xlarge-margin;
        }
      }

      &-top {
        margin-top: $margin-xlarge-margin;
      }

      &-bottom {
        margin-bottom: $margin-xlarge-margin;
      }
    }
  }

  &-padding {
    &-xlarge {
      padding: $padding-large-padding-l;

      &-top {
        margin-top: $padding-large-padding-l;
      }

      &-bottom {
        margin-bottom: $padding-large-padding-l;
      }
    }
  }

  &-border {
    /*
     * Рамка серого цвета
     */
    &-muted {
      border: 1px solid $global-muted-background;
    }

    /*
     * Увеличенный радиус рамки
     */
    &-rounded-large {
      border-radius: 50px;
    }
  }

  &-button {
    text-transform: none !important; // Убираем uppercase у кнопок
    -webkit-appearance: none !important;

    &:disabled {
      opacity: .5;
    }

    // Модификаторы размера
    &-medium {
      line-height: $button-medium-line-height;
    }

    &-warning {
      color: #000;
      background: $color-warning !important;
      @extend .uk-button-primary;

      &:hover {
        color: #000;
        background: darken($color-warning, 5%) !important;
      }

      &-rounded {
        color: #000;
        border: 1px solid $color-warning;
        background: transparent;

        &:hover {
          background-color: $color-warning;
          color: #000;
        }
      }
    }

    &-primary-rounded {
      color: $color-primary;
      border: 1px solid $color-primary;
      background: transparent;

      &:hover {
        background-color: $color-primary;
        color: #fff;
      }
    }

    &-secondary-rounded {
      color: $color-secondary;
      border: 1px solid $color-secondary;
      background: transparent;

      &:hover {
        background-color: $color-secondary;
        color: #fff;
      }
    }

    &-success {
      color: #fff !important;
      background: $color-success;

      &:hover {
        color: #fff !important;
        background: darken($color-success, 10%);
      }
    }

    &-success-rounded {
      color: #000 !important;
      border: 1px solid $color-success;
      background: transparent;

      &:hover {
        background-color: $color-success;
        color: #fff !important;
      }
    }

    &-danger-rounded {
      color: $color-danger !important;
      border: 1px solid $color-danger;
      background: transparent;

      &:hover {
        background-color: $color-danger;
        color: #fff !important;
      }
    }

    &-light-rounded {
      color: #fff;
      border: 1px solid #fff;
      background: transparent;

      &:hover {
        background-color: #fff;
        color: $color-primary;
      }
    }
  }

  &-navbar-dropdown {
    width: 300px;
  }
}

// Убираем отступы контейнера на мобильных
.uk-container\@s {
  @extend .uk-container;
  @include respond-to(small) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.uk-text-bold {
  font-weight: bold;
}

/*
 * Добавляем дополнительные сетки ширины
 */
/* Phone landscape and bigger */
@media (min-width: $breakpoint-small) {
  .uk-child-width-1-7\@s > * {
    width: unquote('calc(100% * 1 / 7.001)');
  }
  .uk-child-width-1-8\@s > * {
    width: unquote('calc(100% * 1 / 8.001)');
  }
}

/* Tablet landscape and bigger */
@media (min-width: $breakpoint-medium) {
  .uk-child-width-1-7\@m > * {
    width: unquote('calc(100% * 1 / 7.001)');
  }
}

/* Desktop and bigger */
@media (min-width: $breakpoint-large) {
  .uk-child-width-1-7\@l > * {
    width: unquote('calc(100% * 1 / 7.001)');
  }
}

/* Large screen and bigger */
@media (min-width: $breakpoint-xlarge) {
  .uk-child-width-1-7\@xl > * {
    width: unquote('calc(100% * 1 / 7.001)');
  }
}

/*
 * Гаттеры сетки
 */
.uk {
  &-grid {
    &-normal {
      &\@s {
        @include respond-from(small) {
          margin-left: -$margin-margin !important;
          & > * {
            padding-left: $margin-margin !important;
          }
          & > .uk-grid-margin {
            margin-top: $margin-margin !important;
          }
        }
      }
    }

    &-small {
      &\@s {
        @include respond-from(small) {
          margin-left: -$margin-small-margin !important;
          & > * {
            padding-left: $margin-small-margin !important;
          }
          & > .uk-grid-margin {
            margin-top: $margin-small-margin !important;
          }
        }
      }
    }

    &-medium {
      &\@s {
        @include respond-from(small) {
          margin-left: -$margin-medium-margin !important;
          & > * {
            padding-left: $margin-medium-margin !important;
          }
          & > .uk-grid-margin {
            margin-top: $margin-medium-margin !important;
          }
        }
      }
    }

    &-large {
      &\@s {
        @include respond-from(small) {
          margin-left: -$margin-large-margin !important;
          & > * {
            padding-left: $margin-large-margin !important;
          }
          & > .uk-grid-margin {
            margin-top: $margin-large-margin !important;
          }
        }
      }
    }

    &-xlarge {
      &\@s {
        @include respond-from(small) {
          margin-left: -$margin-xlarge-margin !important;
          & > * {
            padding-left: $margin-xlarge-margin !important;
          }
          & > .uk-grid-margin {
            margin-top: $margin-xlarge-margin !important;
          }
        }
      }
    }
  }
}

// Чиним тени карточек в слайдере
$gutter-size: 20px;
.slider-shadow-fix {
  padding-left: $gutter-size;
  padding-right: $gutter-size;
  margin-left: -$gutter-size;
  margin-right: -$gutter-size;
}
// Адаптивное видео по всей ширине страницы
// Ютуб обновил вставку iframe
.video {
  position: relative;
  padding-bottom: 56.25%; /*пропорции видео 16:9 */
  //padding-top: 25px;
  height: 0;

  .lyte-wrapper,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
  }

  .fourthree {
    margin: 0 !important;

    .lyte {
      padding-bottom: 56.25%;
    }
  }
}
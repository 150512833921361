/*
* Настройки
*/
$debug: false;

/*
Стили для контентной части
*/
.article {
  /* Возвращаем отображение списка для статей*/
  ul {
    padding-left: 15px;

    li {
      list-style: disc;
    }
  }
}

// Размытие контента
.blured {
  /* Any browser which supports CSS3 */
  filter: blur(1px);

  /* Firefox version 34 and earlier */
  //filter: url("blur.svg#gaussian_blur");

  /* Webkit in Chrome 52, Safari 9, Opera 39, and earlier */
  -webkit-filter: blur(1px);
}

.border {
  &__bottom {
    text-decoration: none;
    border-bottom: 1px solid;

    &:hover {
      text-decoration: none;
    }

    &--primary {
      color: $color-primary;
    }

    &--dashed {
      border-bottom-style: dashed;
    }
  }
}

.dropdown {
  &__menu {
    position: absolute;
    top: 100%;

    padding: 10px 15px;

    background: #fff;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, .3);
    z-index: 999;
    max-width: 1100px;
    width: max-content;
  }
}

.underline {
  border-bottom: 1px solid;

  &:hover {
    text-decoration: none;
  }

  &--dashed {
    display: inline-table;

    border-bottom: 1px dashed;

    &:hover {
      text-decoration: none;
    }
  }
}

/*
Многоколончатый текст
 */
.column {
  &__count {
    &-2 {
      column-count: 2;
    }

    &-3 {
      column-count: 3;
    }

    &-4 {
      column-count: 4;
    }
  }
}

// Эффекты при наведении на блок
.hover-effect {
  &__border {
    &--color
      // Выделение рамкой
    {
      border: 2px solid transparent;

      &:hover {
        border-color: $color-primary;
      }
    }

    &--shadow
      // Тень при наведении
    {
      &:hover {
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, .3);
      }
    }

    &--zoom
      // Зум при наведении
    {
      transition: .3s;
      transform: scale(1.1);
    }
  }
}

// Блок всегда квадратный 1х1
.block-1x1 {
  position: relative;
  overflow: hidden;
  padding-top: 100% !important;
  width: 100%;

  &:before {
    float: left;
    padding-top: 100%;
    content: '';
  }
}

/*
 * Элемент загружается
 */
.rs-in-loading,
.inloading {
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid $global-primary-background;
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    z-index: 9990;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: fade_out(#fff, .3);
    z-index: 999;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

/*
 * Тултип
 * Добавить класс .tooltip и атрибут data-tooltip="username must consist of 29 symbols."
 */
.tooltip {
  position: relative;

  &:before,
  &:after {
    position: absolute;
    content: '';
    opacity: 0;
    transition: all 0.4s ease;
  }

  &:before {
    border-width: 10px 8px 0 8px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    top: -15px;
    transform: translateY(20px);
    z-index: 999;
  }

  &:after {
    content: attr(data-tooltip);
    background: #fff;
    color: #000;
    width: 160px;
    font-size: 12px;
    bottom: 29px;
    left: -10px;
    padding: 10px;
    border-radius: 5px;
    transform: translateY(20px);
    pointer-events: none;
    @extend .uk-box-shadow-xlarge;
  }

  &:hover::before,
  &:hover::after {
    opacity: 1;
    transform: translateY(-2px);
  }
}

@keyframes shake {
  0% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-3deg);
  }
  70% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

#anim:hover {
  animation: shake 500ms ease-in-out forwards;
}
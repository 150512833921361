/*
 * Зависимости от UIKIT
 */

// На мобильной верстке уменьшаем контентный (p, li, ...) текст до 14px
@include respond-to(small) {
  * {
    font-size: 14px;
  }
}

%heading-common {
  font-weight: 400;
  @include respond-to(small) {
    margin-bottom: 15px !important;
  }
}

h1,
.uk-h1 {
  @extend .uk-h1;
  @extend %heading-common;
  @include fluid-type(24px, 42px);
}

h2,
.uk-h2 {
  @extend .uk-h2;
  @extend %heading-common;
  @include fluid-type(24px, 36px);
}

h3,
.uk-h3 {
  @extend .uk-h3;
  @extend %heading-common;
  @include fluid-type(20px, 24px);
}

h4,
.uk-h4 {
  @extend .uk-h4;
  @extend %heading-common;
}

h5,
.uk-h5 {
  @extend .uk-h5;
  @extend %heading-common;
}

h6,
.uk-h6 {
  @extend .uk-h6;
  @extend %heading-common;
}

p {
  @include fluid-type(14px, 16px);
}
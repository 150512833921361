// Основные блоки (например для главной)
$block-padding: 70px;
.block {
  margin: $block-padding 0;

  &:last-child {
    margin-bottom: 0 !important;
  }

  @include respond-to(small) {
    margin: calc(#{$block-padding} / 2) 0 !important;
  }

  &--medium {
    margin: $margin-medium-margin 0 !important;
  }

  &--mobile-remove-margin {
    @include respond-to(small) {
      margin: 0 !important;
    }
  }

  &__title {
    color: #000;
    font-size: 30px;
    font-weight: 600;
    @include respond-to(small) {
      font-size: 20px;
    }
  }

  &__bg {
    margin: 0;
    padding: $block-padding 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include respond-to(small) {
      padding: calc(#{$block-padding} / 2) 0;
    }

    &--blue {
      background-color: #f2f4f6;
    }

    &--yellow {
      background-color: $color-primary;
    }

    &--img {
      background-image: url($path_images + 'block-beton-bg.jpg');
    }
  }
}